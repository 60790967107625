import {router, usePage} from "@inertiajs/vue3"
import {useToast} from "vue-toastification"
import "vue-toastification/dist/index.css"

const toast = useToast()

export const notifications = () => {
    router.on('finish', () => {
        const errors = usePage().props.errors;
        const notification = usePage().props.notification;
        if (notification) {
            toast.success(notification)
        }

        if (Object.values(errors).length) {
            // Concat all errors, seperated by a carriage return :
            toast.error(Object.values(errors).join('\n'))
        }

    })
}
