import { createApp, DefineComponent, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/src/js';
import { Link } from '@inertiajs/vue3';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Lara from '@primevue/themes/lara';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as ConfirmDialog from 'vuejs-confirm-dialog';
import 'dayjs/locale/fr';
import isoWeek from  "dayjs/plugin/isoWeek"
import dayjs from 'dayjs';
import * as Sentry from '@sentry/vue';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import Select from 'primevue/select';
import { definePreset } from '@primevue/themes';
import { notifications } from "@/notification"
import { useSaveLastRoute } from "@/useSaveLastRoute"
import '@/echo';
import { fr } from 'primelocale/fr.json';
import { registerSW } from 'virtual:pwa-register'
import fetcher from '@/fetcher';
import '../css/app.css';

// Import Fetcher on the window object
declare global {
    interface Window { fetcher: any; }
}
window.fetcher = fetcher;

const appName = 'Nooti.cc';

dayjs.locale('fr');
dayjs.extend(isoWeek);

const MyPreset = definePreset(Lara, {
    components: {
        select: {
            root: {
                padding: {
                    x: '0.5rem',
                    y: '0.125rem'
                }
            },
        },
        multiselect:{
            root: {
                padding: {
                    x: '0.25rem',
                    y: '0.125rem'
                },
            },
        },
    }

});

createInertiaApp({
    // title: (title) => `${title} - ${appName}`,
    title: (title) => `${appName}`,
    resolve: (name: string) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ) as Promise<DefineComponent>,

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(createPinia())
            .use(ConfirmDialog)
            .use(Toast, {
                timeout: 2000,
            })
            .use(ZiggyVue)
            .use(notifications)
            .use(useSaveLastRoute)
            .provide('themeVariables', {})
            .component('Link', Link)
            .use(PrimeVue, {
                locale: fr,
                // Default theme configuration
                theme: {
                    preset: MyPreset,
                    options: {
                        prefix: 'p',
                        darkModeSelector: 'system',
                        cssLayer: false
                    }
                }
            })
            .component('InputText', InputText)
            .component('FloatLabel', FloatLabel)
            .component('Select', Select);


        Sentry.init({
            app,
            dsn: 'https://988f94a41a60614459b5b52d4409577c@o4507468751044608.ingest.de.sentry.io/4507468928778320',
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 0.01, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/api\.nooti\.cc/],
            // Session Replay
            replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        Sentry.addEventProcessor(function (event, hint) {
            event.user.id = usePage().props?.user?.id;
            return event;
        });

        app.mount(el);


    },
    progress: {
        color: '#4c76e1',
    },
});


registerSW({ immediate: true })
